<script setup lang="ts">
import type { GridItem } from "~/composables/useGrid";
import PostLike from "../../PostLike.vue";
import { NuxtLink } from "#components";
import { PostType } from "#imports";

const localePath = useLocalePath();
const { getPostTypeLabel } = usePosts();
const { getPostTypeSlug } = usePostTypes();

const { currentPalette } = useTheme();

const emit = defineEmits<{
  mouseenter: [item: GridItem];
  mouseleave: [item: GridItem];
}>();

const props = withDefaults(
  defineProps<{
    item: GridItem;
    lazy?: boolean;
    interactive?: boolean;
    titleTag?: string;
  }>(),
  {
    lazy: false,
    interactive: true,
    titleTag: "h2",
  }
);

const { sharePost } = usePostShare();

const share = () => {
  if (props.item?.post) {
    return sharePost(props.item.post);
  }
};

const { savePost, savePostAfterLogin } = usePostSave();
const { isLoggedIn, openAuthDialog } = useAuth();

const save = () => {
  if (!isLoggedIn.value) {
    savePostAfterLogin.value = props.item.post
      ? {
          post: props.item.post,
          image: props.item.post.cover,
        }
      : null;

    return openAuthDialog();
  }

  if (props.item?.post) {
    return savePost(props.item.post, props.item.post.cover);
  }
};

const { isImageOnAnyUserBoard } = useBoards();
const { isSaveOpen } = usePostSave();
const { user } = useAuth();

const { data: isSaved } = useAsyncData(
  `image:${props.item.post?.cover?.id}:is-saved`,
  async () =>
    props.item.post?.cover?.id
      ? await isImageOnAnyUserBoard(props.item.post.cover.id)
      : false,
  { watch: [isSaveOpen, user] }
);

const logo = computed(() => {
  if (
    props.item.post?.type &&
    [PostType.Professional, PostType.Contractor].includes(props.item.post.type)
  ) {
    const logo = props.item.post?.logo;

    if (logo) {
      const logoHeight = logo.height || 28;
      const logoWidth = logo.width || 28;

      const ratio = 28 / logoHeight;

      const height = logoHeight * ratio;
      const width = logoWidth * ratio;

      return {
        ...logo,
        height,
        width,
        sizes: `${logoWidth}px xs:${logoWidth}px sm:${logoWidth}px md:${logoWidth}px lg:${logoWidth}px xl:${logoWidth}px xxl:${logoWidth}px`,
      };
    }
  }

  return null;
});

const parentPost = computed(() => {
  if (props.item.post?.type && props.item.post.type === PostType.Inspiration) {
    const parentPost = props.item.post.parent_post;

    if (
      parentPost?.type &&
      [PostType.Professional, PostType.Contractor].includes(parentPost.type)
    ) {
      return parentPost;
    }
  }

  return null;
});

const parentPostLogo = computed(() => {
  const logo = parentPost.value?.logo;

  if (logo) {
    const logoHeight = logo.height || 28;
    const logoWidth = logo.width || 28;

    const ratio = 40 / logoHeight;

    const height = logoHeight * ratio;
    const width = logoWidth * ratio;

    return {
      ...logo,
      height,
      width,
      sizes: `${logoWidth}px xs:${logoWidth}px sm:${logoWidth}px md:${logoWidth}px lg:${logoWidth}px xl:${logoWidth}px xxl:${logoWidth}px`,
    };
  }

  return null;
});

const root = templateRef<HTMLElement>("root");

const hover = useElementHover(root);

watch(
  hover,
  (inside) => {
    if (inside) {
      emit("mouseenter", props.item);
    } else {
      emit("mouseleave", props.item);
    }
  },
  { immediate: true }
);

const expanded = ref(false);
const mounted = ref(false);
const interacted = ref(false);
const longTimePassed = ref(false);

onMounted(() => {
  setTimeout(() => {
    mounted.value = true;
  }, 1000);

  setTimeout(() => {
    longTimePassed.value = true;
  }, 5000);
});

const removeListener = useEventListener(
  [
    "mousedown",
    "touchstart",
    "keydown",
    "scroll",
    "focus",
    "mousemove",
    "touchmove",
  ],
  () => {
    interacted.value = true;
    removeListener();
  },
  { passive: true }
);
</script>

<template>
  <article
    ref="root"
    class="post-grid-item select-none group/post"
    :id="props.item.post?.slug ? `post_${props.item.post.slug}` : undefined"
  >
    <div class="post-grid-item__wrapper">
      <div class="post-grid-item__image relative group/post-image">
        <figure
          class="post-grid-item__figure overflow-hidden"
          :class="[currentPalette.bg]"
        >
          <component
            :is="interactive ? NuxtLink : 'div'"
            :to="interactive ? props.item.link : undefined"
            :title="props.item.title"
          >
            <NuxtPicture
              v-bind="props.item.image"
              :alt="props.item.title"
              :img-attrs="{
                class: [
                  'block w-full h-auto',
                  'transition duration-500',
                  {
                    'transform scale-[1.01] hover:scale-110 group-hover/post-image:scale-110 active:scale-100 group-active/post-image:scale-100 group-focus-within/post-image:scale-110':
                      interactive,
                  },
                ],
                draggable: false,
              }"
              :loading="lazy ? 'lazy' : 'eager'"
              :preload="lazy ? false : { fetchPriority: 'high' }"
              sizes="290px xs:353px sm:576px md:692px lg:226px xl:310px xxl:430px"
              format="webp"
              quality="50"
            />
          </component>
          <figcaption class="post-grid-item__figcaption sr-only">
            {{ props.item.title }}
          </figcaption>
        </figure>

        <div
          v-if="props.item.category"
          class="post-grid-item__category absolute top-4 start-4 pointer-events-none"
        >
          <p class="post-grid-item__category-text text-xs leading-3 text-white">
            {{ props.item.category }}
          </p>
        </div>

        <ClientOnly>
          <template
            v-if="interactive && ((mounted && interacted) || longTimePassed)"
          >
            <LazyPostGridItemActions vertical="top" horizontal="end">
              <LazyPostGridItemActionsButton
                title="Dodaj do biblioteki"
                @click="save"
              >
                <PostLike :model-value="isSaved || false" />
              </LazyPostGridItemActionsButton>
            </LazyPostGridItemActions>

            <LazyPostGridItemActions
              vertical="bottom"
              horizontal="end"
              @click="share"
            >
              <LazyPostGridItemActionsButton title="Udostępnij">
                <Icon name="ns:share-2" class="w-[1.125rem] h-[1.125rem]" />
              </LazyPostGridItemActionsButton>
            </LazyPostGridItemActions>
          </template>
        </ClientOnly>
      </div>

      <div class="post-grid-item__content pt-1">
        <div
          class="post-grid-item__header flex items-center justify-between gap-2 whitespace-nowrap"
        >
          <component
            :is="props.titleTag"
            class="post-grid-item__header-title py-[0.3125rem] grow text-ellipsis overflow-hidden font-medium text-sm leading-[1.125rem]"
          >
            <component
              :is="interactive ? NuxtLink : 'div'"
              :to="interactive ? props.item.link : undefined"
              :title="props.item.title"
              class="inline"
            >
              <span class="post-grid-item__header-title-text">
                {{ props.item.title }}
              </span>
            </component>
          </component>

          <div
            class="post-grid-item__header-tag min-w-max text-end font-light text-sm leading-4 text-grey-600"
          >
            <NuxtLink
              v-if="parentPost?.type && parentPostLogo"
              :to="
                localePath({
                  name: 'type-slug',
                  params: {
                    type: getPostTypeSlug(parentPost.type),
                    slug: parentPost.slug,
                  },
                })
              "
              :title="
                [parentPost.title, parentPost.subtitle]
                  .filter(Boolean)
                  .join(' ')
              "
            >
              <NuxtPicture
                provider="directus"
                :src="
                  parentPostLogo.id +
                  (parentPostLogo.filename_download
                    ? '/' +
                      encodeURIComponent(
                        parentPostLogo.filename_download.replace('.svg', '')
                      )
                    : '')
                "
                :width="parentPostLogo.width"
                :height="parentPostLogo.height"
                :modifiers="{ fit: 'contain' }"
                :alt="parentPostLogo.description || parentPost.title"
                :sizes="parentPostLogo.sizes"
                class="block w-auto h-7 max-w-32 translate-y-2"
                :img-attrs="{
                  class: 'block w-auto h-7 max-w-32 object-contain object-top',
                }"
                quality="50"
              />
            </NuxtLink>

            <NuxtLink v-else-if="logo" :to="props.item.link">
              <NuxtPicture
                provider="directus"
                :src="
                  logo.id +
                  (logo.filename_download
                    ? '/' +
                      encodeURIComponent(
                        logo.filename_download.replace('.svg', '')
                      )
                    : '')
                "
                :width="logo.width"
                :height="logo.height"
                :modifiers="{ fit: 'contain' }"
                :alt="logo.description || item.post?.title"
                :sizes="logo.sizes"
                class="block w-auto h-7 max-w-32 translate-y-2"
                :img-attrs="{
                  class: 'block w-auto h-7 max-w-32 object-contain object-top',
                }"
                quality="50"
              />
            </NuxtLink>

            <template v-else>
              <span class="post-grid-item__header-tag-text">
                {{ props.item.tag }}
              </span>
            </template>
          </div>
        </div>

        <div class="post-grid-item__subheader flex items-center">
          <div
            class="relative pb-0.5"
            :class="{ 'overflow-hidden': !expanded }"
          >
            <p
              class="post-grid-item__subheader-subtitle text-xs leading-3 text-grey-700 min-w-32"
            >
              <template v-if="Array.isArray(props.item.subtitle)">
                <span
                  v-if="props.item.subtitle.length > 1"
                  class="post-grid-item__subheader-subtitle-text"
                >
                  {{ props.item.subtitle_label || "Wielu architektów" }}
                </span>
                <span v-else class="post-grid-item__subheader-subtitle-text">
                  {{ props.item.subtitle[0] }}
                </span>
              </template>

              <span v-else class="post-grid-item__subheader-subtitle-text">
                {{ props.item.subtitle }}
              </span>
            </p>

            <div
              v-if="Array.isArray(props.item.subtitle)"
              v-show="expanded"
              class="post-grid-item__subheader-expanded absolute -inset-x-1 -top-1 min-h-[calc(100%_+_0.5rem)] p-1 bg-white"
            >
              <p
                v-for="(subtitle, index) in props.item.subtitle"
                :key="index"
                class="text-xs leading-3 text-grey-700"
              >
                {{ subtitle }}
              </p>
            </div>
          </div>

          <button
            v-if="
              Array.isArray(props.item.subtitle) &&
              props.item.subtitle.length > 1
            "
            class="w-6 h-6 -my-1.5 flex items-center justify-center text-grey-700 cursor-pointer"
            :title="expanded ? 'Zwiń' : 'Rozwiń'"
            @click="expanded = !expanded"
          >
            <Icon
              :name="
                expanded ? 'ns:chevron-up-circle' : 'ns:chevron-down-circle'
              "
              class="w-[0.75rem] h-[0.75rem]"
            />
          </button>
        </div>
      </div>
    </div>
  </article>
</template>
